@import 'styles/variables.scss';

.sponsors {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 60px 0;

  span {
    font-family: $DINPro-Medium;
    text-transform: uppercase;
    color: $light-grey;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 2px;
  }

  &__images-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    img {
      width: 60%;
      @media (max-width: 960px) {
        width: 90%;
      }
    }
  }

  a {
    text-decoration: none;
    color: $gun-powder;
    font-family: $DINPro-Medium;
    font-size: 15px;
    line-height: 18px;
    margin-top: 40px;
  }
}
