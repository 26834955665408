@import 'styles/variables.scss';

.main {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.62) 0%,
    rgba(255, 255, 255, 0.62) 43.44%,
    rgba(241, 244, 255, 0.62) 100%
  );
  position: relative;
  height: 90vh;
  display: flex;
  justify-content: center;
  @media (max-width: 1600px) {
    height: 80vh;
  }
  @media (max-width: 1200px) {
    height: 70vh;
  }
  @media (max-width: 768px) {
    height: 60vh;
  }
  &__background {
    height: 75%;
    background: url('../../../assets/tech-cxo.svg') center bottom no-repeat;
    width: 100%;
    background-size: 80% 90%;
    position: absolute;
    @media (max-width: 768px) {
      background-size: 90% 100%;
    }

    &--gif {
      background: url('../../../assets/tech-cxo.gif') center no-repeat;
      height: 75%;
      width: 100%;
      background-size: contain;
      position: absolute;
      top: 0;
    }
    &--community {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 25%;
      width: 100%;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  &__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 75%;

    &__btn {
      position: absolute;
      bottom: 15%;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        bottom: 12%;
      }
      @media (max-width: 576px) {
        bottom: 10%;
      }
      a {
        position: relative;
        height: 100px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @media (max-width: 768px) {
          height: 80px;
          width: 160px;
        }
        img {
          height: 100%;
          width: 100%;
          @media (max-width: 768px) {
            height: 80%;
            width: 80%;
          }
        }
        span {
          margin-top: 21px;
          position: absolute;
          color: $ultramarine-blue;
          font-size: 33px;
          line-height: 42px;
          font-family: 'Dosis', sans-serif;
          text-transform: uppercase;
          @media (max-width: 768px) {
            font-size: 26px;
            line-height: 33px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
