* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
}

@font-face {
  font-family: 'DINPro Medium';
  src: url('../assets/fonts/DINPro-Medium.ttf') format('truetype'),
    url('../assets/fonts/DINPro-Medium.otf') format('opentype');
}
